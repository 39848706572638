.column-flex {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}
.column-flex .container {
  flex: 1;
}
.search-plate {
  background: #fff;
  padding: 25px 30px;
  margin-bottom: 15px;
  border-radius: 5px;
}
.search-plate .ant-btn {
  margin-left: 10px;
}
.search-plate .ant-btn-primary {
  background: #216AEC;
}
.search-plate :deep .ant-select {
  width: 230px !important;
  margin-right: 10px;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dot {
  width: 8px;
  height: 8px;
  border-radius: 8px;
}
.ant-form {
  display: flex;
  flex-flow: row wrap;
}
.ant-form :deep .ant-form-item {
  display: flex;
  flex-flow: column nowrap;
  margin: 0 35px 12px 0;
  height: 64px;
}
.ant-form :deep .ant-form-item .ant-input {
  border-color: #EBEDF0;
}
:deep .ant-form-item-label > label {
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: rgba(77, 96, 134, 0.85);
}
.detail-form {
  position: fixed;
  left: calc((100vw - 1200px) / 2 + 240px);
  top: calc(32vh);
  width: 800px;
  height: 405px;
  padding: 13px 25px 25px;
  border-radius: 5px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
  z-index: 9;
}
